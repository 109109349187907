
























































































































































import { AnyObject, EventPayload } from '@movecloser/front-core'
import { Component, Mixins } from 'vue-property-decorator'

import { AllowedAttributes } from '../../../../../contexts'
import { Inject, logger } from '../../../../../support'
import { toImageProps } from '../../../../shared/support'
import { ImageProps } from '../../../../../dsl/atoms/Image'

import { VariantsSwitch } from '../../../molecules/VariantsSwitch'
import { IProductsRepository, ProductsRepositoryType } from '../../../contracts/repositories'

import { AttributeData } from '../ProductCard.contracts'
import { AttributesParser } from '../partials/AttributesParser.vue'
import ProductCardMixin from '../ProductCard.mixin'
import Price from '../../../../shared/molecules/Price/Price.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductCardRich>({
  name: 'ProductCardRich',
  components: { AttributesParser, Price, VariantsSwitch },
  mounted (): void {
    /**
     * @inheritDoc
     */
    this.initComponentAttributes()

    if (this.shouldAddToCart) {
      this.eventBus.handle('app:cart.remove', (event: EventPayload<AnyObject>) => {
        if (event.payload) {
          if (event.payload.items[0].id === this.activeVariant?.sku) {
            this.itemAdded = false
          }
        }
      })
    }
  }
})
export class ProductCardRich extends Mixins<ProductCardMixin>(ProductCardMixin) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  public mainAttributesData: AttributeData[] | null = null

  public additionalAttributesData: AttributeData[] | null = null

  public hasAllGiftsAvailable: boolean = true

  public get massDetails (): string[] {
    if (!this.activeVariant) {
      return []
    }

    const details = []
    if (this.activeVariant.attributes.volumeName) {
      details.push('volumeName')
    }

    if (this.activeVariant.attributes.weightName) {
      details.push('weightName')
    }

    if (this.activeVariant.attributes.colorName) {
      details.push('colorName')
    }

    return details
  }

  public get wasItemAdded (): boolean {
    return this.itemAdded
  }

  public get wishlistBtnTitle (): string {
    return this.$t(`front.shared.wishlist.${this.isFavourite ? 'remove' : 'add'}`).toString()
  }

  /**
   * Description shortened to specific amount of characters
   */
  public get shortenedName (): string {
    const MAX_DESC_LENGTH = 30

    if (!this.activeVariant) {
      return ''
    }

    if (this.activeVariant.name.length) {
      if (this.activeVariant.name.length < MAX_DESC_LENGTH) {
        return this.activeVariant.name
      }

      return `${this.activeVariant.name.substring(0, MAX_DESC_LENGTH)}...`
    }

    return ''
  }

  /**
   * Checks whether all variants of the current product are unavailable
   */
  public get allVariantsUnavailable (): boolean {
    if (!this.resolvedProduct) {
      return false
    }

    const temp = []
    for (const variant of Object.values(this.resolvedProduct.variants)) {
      if (variant.isAvailable) {
        temp.push(variant)
      }
    }

    return temp.length === 0
  }

  public get finalPrice () {
    return this.shouldDisplayNetto
      ? this.activeVariant?.price.finalPriceNet
      : this.activeVariant?.price.finalPrice
  }

  public get regularPrice () {
    return this.shouldDisplayNetto
      ? this.activeVariant?.price.regularPriceNet
      : this.activeVariant?.price.regularPrice
  }

  public get priceSuffix (): string {
    return this.shouldDisplayNetto
      ? this.$t(`front.shared.cartItem.${this.isUserB2B ? 'nett' : 'gross'}`).toString()
      : ''
  }

  public get shouldDisplayMainCategory (): boolean {
    if (this.displayMainCategoryLogo) {
      return !!this.product.category.logo
    }

    return true
  }

  public get mainCategoryLogo (): ImageProps | null {
    if (this.displayMainCategoryLogo && this.product.category.logo) {
      return toImageProps(this.product.category.logo)
    }

    return null
  }

  /**
   * Define count of all variants (including unavailable)
   */
  public get variantsCount (): number {
    return this.variants.length
  }

  /**
   * Determines variant count suffixes
   */
  public get variantsCountSuffix (): string {
    if (!this.activeVariant?.identifier.variant_color) {
      return 'capacity'
    }

    return 'variant'
  }

  /**
   * Return attributes with proper data based on AllowedAttribute type
   */
  public getAttributesData (attributes: string[]): AttributeData[] | null {
    const attr: AttributeData[] = []
    let candidateAttr = attributes

    if (!attributes || attributes.length === 0) {
      return null
    }

    if (!this.hasAllGiftsAvailable) {
      candidateAttr = candidateAttr.filter((attr) => attr !== AllowedAttributes.HasGift)
    }

    const badgeRegistry: Record<string, any> = Object.entries(this.badgeRepository)
      .reduce((acc, [key, value]) => {
        if (this.disabledBadgeIcons.includes(key)) {
          return acc
        }

        return { ...acc, [key]: value }
      }, {})

    for (const value of Object.values(candidateAttr)) {
      const badge = badgeRegistry[value] ?? badgeRegistry.default

      if (value === AllowedAttributes.IsSale && this.activeVariant) {
        attr.push({
          value,
          label: `-${100 - (Math.round((this.activeVariant.price.finalPrice / this.activeVariant.price.regularPrice) * 100))}%`,
          ...badge
        })
      } else if (value === AllowedAttributes.IsSponsored) {
        attr.push({
          value,
          label: this.$t(`front.products.organisms.productCard.attributes.${value}`).toString(),
          tooltip: this.$t('front.products.organisms.productCard.attributes.sponsoredTooltip').toString(),
          ...badge
        })
      } else {
        attr.push({
          value,
          label: this.$t(`front.products.organisms.productCard.attributes.${value}`).toString(),
          ...badge
        })
      }
    }

    return attr
  }

  /**
   * Set specific attributes for ProductCard from config
   * @param mainAttributes
   * @param additionalAttributes
   * @protected
   */
  protected setAttributeTypes (mainAttributes: string[], additionalAttributes: string[]): void {
    const main: string[] = []
    let additional: string[] = []

    if (!this.activeVariant) {
      this.mainAttributesData = this.getAttributesData(main)
      this.additionalAttributesData = this.getAttributesData(additional)
      return
    }

    for (const [attrKey, attrValue] of Object.entries(this.activeVariant.attributes)) {
      if (mainAttributes) {
        for (const value of Object.values(mainAttributes)) {
          if (value === attrKey) {
            if (value === attrKey && attrValue === true) {
              main.push(String(attrKey))
            }
          }
        }
      }

      if (additionalAttributes) {
        for (const value of Object.values(additionalAttributes)) {
          if (value === attrKey) {
            if (value === attrKey && attrValue === true) {
              additional.push(String(attrKey))
            }
          }
        }
      }
    }

    /** Add isSale attribute regarding current promotional price */
    if (!this.activeVariant.attributes.isSale && this.activeVariant.price.finalPrice < this.activeVariant.price.regularPrice) {
      main.push(AllowedAttributes.IsSale)
    }

    if (additional.includes(AllowedAttributes.HasGift) && !this.hasAllGiftsAvailable) {
      additional = additional.filter((attr) => attr !== AllowedAttributes.HasGift)
    }

    this.mainAttributesData = this.getAttributesData(main)
    this.additionalAttributesData = this.getAttributesData(additional)
  }

  /**
   * Init component's allowed attributes
   * @protected
   */
  protected initComponentAttributes (): void {
    this.setAttributeTypes(this.mainAttributes, this.additionalAttributes)
  }

  private get brand (): string | undefined {
    if (typeof this.getAttribute<string>(AllowedAttributes.Brand) === 'undefined') {
      return
    }

    return this.getAttribute<string>(AllowedAttributes.Brand)
  }

  /**
   * Checks whether every possible gifts are unavailable. Necessary to hide `GiftsBox` partial
   * @protected
   */
  protected async checkGiftsAvailability (): Promise<void> {
    if (!this.activeVariant) {
      return
    }

    const possibleGifts = this.activeVariant.attributes[AllowedAttributes.GiftsSku] as string[]

    if (!possibleGifts || possibleGifts.length === 0) {
      return
    }

    try {
      const gifts = await this.productsRepository.loadProductsBySkus(possibleGifts)

      if (!gifts || gifts.length === 0) {
        return
      }

      this.hasAllGiftsAvailable = gifts.every((gift) => Object.values(gift.variants)[0].isAvailable && Object.values(gift.variants)[0].sellableQuantity > 0)
    } catch (e) {
      logger(e, 'warn')
    }
  }
}

export default ProductCardRich
